
import { defineComponent } from 'vue';
import store from '@/store';
import AppHeader from '@/components/common/AppHeader.vue';
import AppContent from '@/components/common/AppContent.vue';
import AppFooter from '@/components/common/AppFooter.vue';

export default defineComponent({
  name: 'App',
  components: {
    AppHeader,
    AppContent,
    AppFooter,
  },
  computed: {
    theme() {
      return store.getters['commonStore/getTheme'];
    },
  },
  watch: {
    theme(value) {
      document.documentElement.className = value;
    },
  },
  created() {
    document.documentElement.className = this.theme;
  },
});
