
import { defineComponent } from 'vue';
import store from '@/store';

export default defineComponent({
  name: 'AppHeader',
  computed: {
    isDarkTheme(): boolean {
      return store.getters['commonStore/isDarkTheme'] ?? false;
    },
  },
  methods: {
    toggleTheme() {
      store.dispatch('commonStore/toggleTheme');
    },
  },
});
