
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppContent',
  computed: {
    title() {
      return this.$route.meta?.title;
    },
  },
});
