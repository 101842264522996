import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_app_content = _resolveComponent("app-content")!
  const _component_app_footer = _resolveComponent("app-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_app_header)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_content)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_app_footer)
    ])
  ], 64))
}