import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-556669ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-container" }
const _hoisted_2 = { class: "content-wrapper" }
const _hoisted_3 = { class: "content-item-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", { class: "title" }, _toDisplayString(_ctx.title), 513), [
        [_vShow, _ctx.title]
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            _createVNode(_Transition, {
              name: "component-fade",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { class: "content-item" }))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}